import GameScene from '../scenes/GameScene';
import config from '../../../../config';
import { sfxr } from '../sound/sfxr.js';
import sfLibrary from '../sound/fxLibrary';
// export enum PlayerStates {
//   STANDING,
//   FALLING,
//   CROUCHING,
//   JUMPING,
//   WALKING,
// }

export default class Player extends Phaser.GameObjects.Container {
  // public scene: GameScene;
  // public body: Phaser.Physics.Arcade.Body;
  public score: number = 0;
  public scoreLabel: Phaser.GameObjects.Text;
  circle: Phaser.GameObjects.Arc;
  inJail: boolean;
  isCurrentPlayer: boolean;
  energy: number;
  playerLabel: Phaser.GameObjects.Text;
  playerState: any;

  constructor(scene: GameScene, x: number, y: number, name: string, color = 0xff0000, playerState) {
    super(scene, x, y);
    this.name = name;
    this.playerState = playerState;
    const radius = config.maps.smear.player.diameter / 2;
    const circleColor = Phaser.Display.Color.IntegerToColor(color).darken(30).color;
    const circle = new Phaser.GameObjects.Arc(
      scene,
      0,
      0,
      radius,
      0,
      360,
      false,
      circleColor,
      0.9
    );

    circle.setStrokeStyle(2, 0xffffff, 1);

    this.circle = circle;

    const innerRadius = (config.maps.smear.player.diameter / 2) * 0.4;
    const innerCircle = new Phaser.GameObjects.Arc(
      scene,
      0,
      0,
      innerRadius,
      0,
      360,
      false,
      0x000000,
      1
    );

    var textStyle = {
      font: '14px Arial',
      fill: '#666666',
      align: 'center',
    };

    this.playerLabel = new Phaser.GameObjects.Text(
      scene,
      0,
      radius + 8,
      name,
      textStyle
    );
    this.playerLabel.setShadow(0, 0, 'rgba(0,0,0,1)', 1);
    this.playerLabel.setAlign('center');
    this.playerLabel.setOrigin(0.5);

    var scoreStyle = {
      font: '14px Arial',
      fill: '#dddddd',
      align: 'center',
    };
    const scoreLabel = new Phaser.GameObjects.Text(
      scene,
      0,
      0,
      this.score.toString(), 
      scoreStyle
    );
    scoreLabel.setShadow(0, 0, 'rgba(0,0,0,1)', 1);
    scoreLabel.setAlign('center');
    scoreLabel.setOrigin(0.5);

    this.scoreLabel = scoreLabel;

    this.add(circle);
    this.add(innerCircle);
    this.add(this.playerLabel);
    this.add(scoreLabel);

    this.scene.add.existing(this);

    // super(scene, x, y, "player");

    // const animations: Phaser.Types.Animations.Animation[] = [
    //   { key: "stand", frames: this.scene.anims.generateFrameNumbers(this.texture.key, { frames: [0] }) },
    //   {
    //     key: "walk",
    //     frameRate: 12,
    //     frames: this.scene.anims.generateFrameNumbers(this.texture.key, { frames: [1, 2, 0] }),
    //     repeat: -1,
    //   },
    //   { key: "jump", frames: this.scene.anims.generateFrameNumbers(this.texture.key, { frames: [2] }) },
    //   { key: "crouch", frames: this.scene.anims.generateFrameNumbers(this.texture.key, { frames: [3] }) },
    // ];
    // animations.forEach((animation) => this.scene.anims.create(animation));

    // this.scene.physics.world.enable(this);

    // this.scene.collisionGroup.add(this);

    // this.body.setAllowDrag(true).setMaxVelocityX(160);

    // this.scene.add
    //   .existing(this)
    //   .setSize(24)
    //   .setCollideWorldBounds(true)
    //   .setDragX(Math.pow(16, 2))
    //   .setState(PlayerStates.STANDING);
  }

  public setScore(score: number) {
    if (this.score != score) {
      this.score = score;
      this.scoreLabel.setText(this.score.toString());
    }
  }

  public setHealth(health: number) {
    // this.circle.setAlpha(health / 100);
    this.circle.setStrokeStyle(2, 0xffffff, health / 100);
  }

  public setJail(inJail: boolean) {
    if (inJail !== this.inJail) {
      this.inJail = inJail;
      if (this.inJail) {
        if (!this.scene.game.sound.mute) {
          const a = sfxr.toAudio(sfLibrary.player.explosion);
          a.play();
        };
      } else {
        // if (!this.scene.game.sound.mute) {
        //   const a = sfxr.toAudio(sfLibrary.player.spawn);
        //   a.play();
        // };
      }
    }
  }

  public setPlayerName(name: string) {
    if (this.name !== name) {
      this.name = name;
      this.playerLabel.setText(name);
    }
  }

  public setCurrentPlayer(isCurrentPlayer: boolean) {
    this.isCurrentPlayer = isCurrentPlayer;
    if (this.isCurrentPlayer) {
      this.circle.setFillStyle(
        Phaser.Display.Color.IntegerToColor(this.circle.fillColor).lighten(30).color
      );
    }
  }

  // public setState(value: PlayerStates) {
  //   switch (value) {
  //     case PlayerStates.CROUCHING:
  //       this.setSize(16).play("crouch");
  //       break;

  //     case PlayerStates.FALLING:
  //       this.setSize(24).play("jump");
  //       break;

  //     case PlayerStates.JUMPING:
  //       this.setSize(24).setVelocityY(-260).play("jump").playAudio("jump");
  //       break;

  //     case PlayerStates.STANDING:
  //       this.setSize(24)
  //         .setVelocityX(this.body.velocity.x * 0.5)
  //         .play("stand");
  //       break;

  //     case PlayerStates.WALKING:
  //       this.setSize(24).play("walk");
  //       break;
  //   }

  //   return super.setState(value);
  // }

  // public preUpdate(time: number, delta: number) {
  // const { left, right, down, jump } = this.scene.inputs;
  // const flipX = left && !right ? true : right ? false : this.flipX;
  // const directionX = -Number(left) + Number(right);
  // const accelerationX = directionX * Math.pow(16, 2);

  // switch (this.state) {
  //   case PlayerStates.STANDING:
  //     if (!this.body.onFloor()) {
  //       this.setState(PlayerStates.FALLING);
  //     } else if (jump) {
  //       this.setState(PlayerStates.JUMPING);
  //     } else if (left || right) {
  //       this.setState(PlayerStates.WALKING);
  //     } else if (down) {
  //       this.setState(PlayerStates.CROUCHING);
  //     }
  //     break;

  //   case PlayerStates.WALKING:
  //     this.setFlipX(flipX).setAccelerationX(accelerationX);

  //     if (!this.body.onFloor()) {
  //       this.setState(PlayerStates.FALLING);
  //     } else if (jump) {
  //       this.setState(PlayerStates.JUMPING);
  //     } else if (!left && !right) {
  //       if (down) {
  //         this.setState(PlayerStates.CROUCHING);
  //       } else {
  //         this.setState(PlayerStates.STANDING);
  //       }
  //     }
  //     break;

  //   case PlayerStates.CROUCHING:
  //     if (!this.body.onFloor()) {
  //       this.setState(PlayerStates.FALLING);
  //     } else if (jump) {
  //       this.setState(PlayerStates.JUMPING);
  //     } else if (!down) {
  //       this.setState(PlayerStates.STANDING);
  //     }
  //     break;

  //   case PlayerStates.JUMPING:
  //     if (this.body.velocity.y > 0) {
  //       this.setState(PlayerStates.FALLING);
  //     } else if (!jump) {
  //       this.setVelocityY(this.body.velocity.y * 0.9);
  //     }

  //   case PlayerStates.FALLING:
  //     this.setFlipX(flipX).setAccelerationX(accelerationX);

  //     if (this.body.onFloor()) {
  //       if (left || right) {
  //         this.setState(PlayerStates.WALKING);
  //       } else {
  //         this.setState(PlayerStates.STANDING);
  //       }
  //     }
  //     break;
  // }

  //   super.preUpdate(time, delta);
  // }

  // public setSize(height: number) {
  //   super.setSize(16, height);

  //   this.body.setOffset(0, this.height - height);

  //   return this;
  // }

  // public playAudio(key: string) {
  //   this.scene.sound.play(key, { volume: 0.5 });

  //   return this;
  // }
}
