import GameScene from "../scenes/GameScene";

export interface Keys {
  W: Phaser.Input.Keyboard.Key;
  A: Phaser.Input.Keyboard.Key;
  S: Phaser.Input.Keyboard.Key;
  D: Phaser.Input.Keyboard.Key;
  Q: Phaser.Input.Keyboard.Key;
  E: Phaser.Input.Keyboard.Key;
  G: Phaser.Input.Keyboard.Key;

  Z: Phaser.Input.Keyboard.Key;
  X: Phaser.Input.Keyboard.Key;
  C: Phaser.Input.Keyboard.Key;
  up: Phaser.Input.Keyboard.Key;
  down: Phaser.Input.Keyboard.Key;
  left: Phaser.Input.Keyboard.Key;
  right: Phaser.Input.Keyboard.Key;
  shift: Phaser.Input.Keyboard.Key;
  enter: Phaser.Input.Keyboard.Key;
  space: Phaser.Input.Keyboard.Key;
  comma: Phaser.Input.Keyboard.Key;
  period: Phaser.Input.Keyboard.Key;
}

export default class Inputs {
  private _input: Phaser.Input.InputPlugin;
  private _keys: Keys;
  private _padIndex = 0;
  private _camera: Phaser.Cameras.Scene2D.Camera;
  private _firePrimary: boolean;
  joyStick: any;
  private joystickState = {
    up: false,
    down: false,
    left: false,
    right: false,
  }
  // private fireSecondary: boolean;

  constructor(
    scene: GameScene,
    input: Phaser.Input.InputPlugin,
    camera: Phaser.Cameras.Scene2D.Camera
  ) {
    this._input = input;
    this._camera = camera;
    this._keys = this._input.keyboard.addKeys(
      'W,A,S,D,Q,E,G,Z,X,C,up,left,down,right,shift,space,enter,comma,period'
    ) as Keys;

    input.mouse.disableContextMenu(); // disable right click

    input.on('pointerdown', (pointer) => {
      // console.log(pointer);
      if (pointer.leftButtonDown() && !this._firePrimary) {
        this._firePrimary = true;
      }
      // if (pointer.rightButtonDown()) {
      //     if (pointer.getDuration() > 500) {
      //         this.add.image(pointer.x, pointer.y, 'disk');
      //     } else {
      //         this.add.image(pointer.x, pointer.y, 'asuna');
      //     }
      // }
      // else if (pointer.getDuration() > 500) {
      //     this.add.image(pointer.x, pointer.y, 'tree');
      // } else {
      //     this.add.image(pointer.x, pointer.y, 'logo');
      // }
    });
    input.on('pointerup', (pointer) => {
      // if (pointer.leftButtonUp() && this._firePrimary) {
      //   this._firePrimary = false;
      // }
      if (pointer.leftButtonReleased()) {
        this._firePrimary = false;
        // this.text2.setText('Left Button was released');
      } else if (pointer.rightButtonReleased()) {
        // this.text2.setText('Right Button was released');
      } else if (pointer.middleButtonReleased()) {
        // this.text2.setText('Middle Button was released');
      } else if (pointer.backButtonReleased()) {
        // this.text2.setText('Back Button was released');
      } else if (pointer.forwardButtonReleased()) {
        // this.text2.setText('Forward Button was released');
      }
    });

    input.addPointer(1); // for multi-touch

    if ('ontouchstart' in window || (navigator.maxTouchPoints > 0)) {
      const heightOfJoystick = window.innerHeight / 10;
      const joystickPadding = window.innerHeight / 20;
      const joystickPlugin: any = scene.plugins.get('rexvirtualjoystickplugin');
      this.joyStick = joystickPlugin.add(this, {
        x: joystickPadding + heightOfJoystick,
        y: (window.innerHeight - heightOfJoystick) - joystickPadding,
        radius: heightOfJoystick,
        base: scene.add.circle(0, 0, heightOfJoystick, 0x888888),
        thumb: scene.add.circle(0, 0, heightOfJoystick/2, 0xcccccc),
        // dir: '8dir',   // 'up&down'|0|'left&right'|1|'4dir'|2|'8dir'|3
        // forceMin: 16,
        // enable: true
      })
      .on('update', () => {
        var cursorKeys = this.joyStick.createCursorKeys();
        for (var name in cursorKeys) {
          this.joystickState[name] = cursorKeys[name].isDown;
        }
      });
    }
  }

  public setPadIndex(value: number) {
    if (this.pads.length > value) {
      this._padIndex = value;
    }

    return this;
  }

  public get left() {
    return this.keys.left.isDown || this.keys.A.isDown
     || this.joystickState['left']
     || this.padAxisH === -1;
  }

  public get right() {
    return this.keys.right.isDown || this.keys.D.isDown
    || this.joystickState['right']
    || this.padAxisH === 1;
  }

  public get up() {
    return this.keys.up.isDown || this.keys.W.isDown
    || this.joystickState['up']
    || this.padAxisV === -1;
  }

  public get down() {
    return this.keys.down.isDown || this.keys.S.isDown
    || this.joystickState['down']
    || this.padAxisV === 1;
  }

  public get sprint() {
    return this.keys.shift.isDown;
  }

  public get firePrimary() {
    if (this._firePrimary) {
      this._firePrimary = false;
      return true;
    }
    // return this.firePrimary;
  }

  public get fireBomb() {
    return this.keys.G.isDown;
  }

  public get fireMissile() {
    return this.keys.Q.isDown;
  }

  public get fireBouncy() {
    return this.keys.E.isDown;
  }

  // public get clickPrimary() {
  //   return !!this._input.activePointer.leftButtonDown() && !this.isClickingPrimary; //.leftButtonDown; //leftButton.isDown
  // }

  // public get clickSecondary() {
  //   return !!this._input.activePointer.rightButtonDown(); //leftButton.isDown
  // }

  // public get clickMiddle() {
  //   return !!this._input.activePointer.middleButtonDown(); //leftButton.isDown
  // }

  public get pointerWorldPosition() {
    // pointer.worldX
    this._input.activePointer.updateWorldPoint(this._camera);
    return [this._input.activePointer.worldX, this._input.activePointer.worldY];
    // return this._input.activePointer.position;
  }

  protected get keys() {
    return this._keys;
  }

  protected get padA() {
    return this.padButtons.some(
      (button) => button.index % 2 === 1 && button.value === 1
    );
  }

  protected get padB() {
    return this.padButtons.some(
      (button) => button.index % 2 === 0 && button.value === 1
    );
  }

  protected get padAxisH() {
    if (this.pad) {
      const [x] = this.pad.axes;

      return x.getValue();
    }

    return 0;
  }

  protected get padAxisV() {
    if (this.pad) {
      const [_, y] = this.pad.axes;

      return y.getValue();
    }

    return 0;
  }

  protected get padButtons() {
    return this.pad?.buttons ?? [];
  }

  protected get pad() {
    return this.pads[this._padIndex];
  }

  protected get pads() {
    return this._input.gamepad.gamepads;
  }
}
