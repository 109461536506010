import GameScene from '../scenes/GameScene';
import config from '../../../../config';

export default class Wall extends Phaser.GameObjects.Container {
  circle: Phaser.GameObjects.Arc;
  constructor(
    scene: GameScene,
    x: number,
    y: number,
    radius: number,
    color: number,
    width: number,
    fillColor?: number,
    fillAlpha?: number,
  ) {
    // const width = ;
    if (!fillColor) {
      fillColor = 0xffffff;
    }
    if (!fillAlpha) {
      fillAlpha = 0.1;
    }

    super(scene, x, y);
    const circle = new Phaser.GameObjects.Arc(
      scene,
      0,
      0,
      radius,
      0,
      360,
      false
    );
    circle.setFillStyle(fillColor, fillAlpha);
    this.circle = circle;
    circle.setStrokeStyle(width, color);
    this.add(circle);
    this.scene.add.existing(this);
  }

  setHillColor(color: number) {
    this.circle.setFillStyle(color, 0.1);
  }
}
