import getCirclePos from './common/functions/getCirclePos';

// const getMongoUrl = function() {
//     const url = 'mongodb://localhost:27017/fortwars';
//     // if(process.env.MYMONGO_MONGODB_SERVICE_HOST && process.env.MYMONGO_MONGODB_SERVICE_PORT) {
//     //     url = 'mongodb://'+process.env.MYMONGO_MONGODB_SERVICE_HOST+':'+process.env.MYMONGO_MONGODB_SERVICE_PORT+'/fortwars';
//     // }
//     // if(process.env.MONGO_REPL_MONGODB_REPLICASET_0_SERVICE_HOST && process.env.MONGO_REPL_MONGODB_REPLICASET_0_SERVICE_PORT) {
//     //     url = 'mongodb://'+process.env.MONGO_REPL_MONGODB_REPLICASET_0_SERVICE_HOST+':'+process.env.MONGO_REPL_MONGODB_REPLICASET_0_SERVICE_PORT+','+process.env.MONGO_REPL_MONGODB_REPLICASET_1_SERVICE_HOST+':'+process.env.MONGO_REPL_MONGODB_REPLICASET_1_SERVICE_PORT+','+process.env.MONGO_REPL_MONGODB_REPLICASET_2_SERVICE_HOST+':'+process.env.MONGO_REPL_MONGODB_REPLICASET_2_SERVICE_PORT+'/fortwars?replicaSet=rs0';
//     // }
//     return url;
// }

let stage = 'development';
let colysusUrl = 'ws://localhost:2567';
// let colysusUrl = 'wss://5983-69-76-2-74.ngrok-free.app';

if (process.env.NODE_ENV === 'production') {
    colysusUrl = 'wss://www.fortwars.io';
    stage = 'production';
}

type SmearTeam = {
    i: number,
    color: string,
    spawn: { x: number, y: number },
    jail: { x: number, y: number },
    playerCount: number,
    botCount: number,
}

type Wall = {
    id: string,
    barrier: boolean | undefined,
    x: number,
    y: number,
    r: number,
    width: number,
    spawnTeam: number,
    hill: boolean | undefined,
}

const WORLD_DIMENSION = 2500;
const WORLD_LENGTH = WORLD_DIMENSION;
const WORLD_WIDTH = WORLD_DIMENSION;
const WORLD_PADDING = 200;
const FLAG_POST_RADIUS = 24;

const WALL_WIDTH = 9;
const WALL_BARRIER_WIDTH = WALL_WIDTH * 2;
const JAIL_CELL_OFFSET = WORLD_DIMENSION / 4;
const JAIL_CELL_RADIUS = WORLD_DIMENSION / 20;
const TEAM_BARRIER_RADIUS = 70;

const config = {
    stage: stage,
    aws: {
        region: 'us-west-2',
    },
    stats: {
        playerStatsTable: 'FortwarsPlayerStats',
        updateInterval: 1000 * 60 * 5,
    },
    fixedTimeStep: 1000 / 60,
    domainName: 'fortwars.io',
    // mongoUrl: getMongoUrl(),
    colysusUrl: colysusUrl,
    inactivityTimeout: stage === 'development' ? 1000 * 60 * 15 : 1000 * 60 * 5,
    anonymouseUsername: 'Anonymous',
    scoreToSetUsername: 10,
    maps: {
        smear: {
            width: WORLD_DIMENSION,
            height: WORLD_DIMENSION,
            padding: WORLD_PADDING,
            wallWidth: WALL_WIDTH,
            spawnPathWidth: 32,
            jailTime: 10 * 1000,
            numberOfTeams: 4,
            minimumDesiredPlayers: 16,
            player: {
                moveSpeed: 1, // 5
                diameter: 50,
                mass: 40,
                healthMax: 100,
                healthPerKill: 10,
                energyMax: 100,
                energyRegen: 0.15,
            },
            score: {
                perKill: 1,
                perDeath: -1,
                perFlagCaptureBearer: 2,
                perFlagCaptureTeam: 5,
            },
            // flagPostDiameter: FLAG_POST_RADIUS,
            flagPostRadius: FLAG_POST_RADIUS,
            flagRadius: FLAG_POST_RADIUS / 2,
            flagRespawnTime: 5 * 1000,
            attack: {
                regenRate: 0.4,
                missileRegenRate: 0.1,
                bouncyRegenRate: 0.2,
                bombRegenRate: 0.08,
            },
            walls: [
                {
                    id: 'outside',
                    barrier: true,
                    x: (WORLD_WIDTH / 2),
                    y: (WORLD_LENGTH/2),
                    r: (WORLD_DIMENSION/2) - WORLD_PADDING,
                    width: WALL_BARRIER_WIDTH,
                    spawnTeam: undefined,
                },
                {
                    id: 'flagRoom',
                    hill: true,
                    x: (WORLD_WIDTH / 2),
                    y: (WORLD_LENGTH/2),
                    r: (WORLD_DIMENSION/5) - WORLD_PADDING,
                    width: WALL_WIDTH,
                    spawnTeam: undefined,
                },
            ] as Wall[],
            flagPosts: [
                {
                    r: FLAG_POST_RADIUS,
                    x: WORLD_WIDTH/2,
                    y: WORLD_LENGTH/2, team: -1 //, hasFlag: true
                }
            ],
            teams: [] as SmearTeam[],
        },
    },
    bullets: {
        laser: {
            speed: 0.3,
            radius: 4,
            damage: 10,
            energy: 10,
            level: 0,
        },
        missile: {
            speed: 0.25,
            radius: 4,
            damage: 60,
            energy: 50,
            level: 8,
        },
        bouncy: {
            speed: 0.5,
            radius: 4,
            damage: 30,
            energy: 33,
            level: 5,
        },
        bomb: {
            speed: 0.1,
            radius: 24,
            damage: 60,
            energy: 50,
            level: 10,
        },
        bombSrapnel: {
            speed: 0.3,
            radius: 4,
            damage: 30,
            energy: 10,
            distance: 100,
            level: 10,
        },
    },
    sprint: {
        energy: 4,
        speedMultiplier: 2
    },
    splash: {
        height: 500,
        width: 800,
        discordUrl: 'https://discord.gg/rp9rUsTdwS',
    },
}

const teamColors = [
    'green',
    'red',
    'blue',
    'yellow',
    'orange',
    'purple',
    'brown',
    'pink',
    'cyan',
    'magenta',
    'maroon',
    'grey'
];

const getTeamSpawn = function(teamNumber: number, numberOfTeams: number = config.maps.smear.numberOfTeams) {
    const degs = 360 / numberOfTeams;
    let r = (((WORLD_DIMENSION / 2)));
    r =  r - (WORLD_PADDING) - TEAM_BARRIER_RADIUS;
    return getCirclePos(0, r * -1, degs * teamNumber, WORLD_DIMENSION);
}

const getTeamJail = function(teamNumber: number, numberOfTeams: number = config.maps.smear.numberOfTeams) {
    const degs = (360 / numberOfTeams);
    let r = (((WORLD_DIMENSION / 2)));
    r = 0 - JAIL_CELL_OFFSET - JAIL_CELL_RADIUS;
    return getCirclePos(0, r, ((degs * teamNumber) + (degs / 2)), WORLD_DIMENSION);
}

const getFlagPostForTeam = function(teamNumber: number, numberOfTeams: number = config.maps.smear.numberOfTeams) {
    var degs = 360 / numberOfTeams;
    var r = WORLD_WIDTH / 2;
    r =  r - (WORLD_PADDING) - TEAM_BARRIER_RADIUS;
    return getCirclePos(0, r * -1, degs * teamNumber, WORLD_DIMENSION);
}

const addTeam = function(teamNumber: number) {
    const team = {
        i: teamNumber,
        color: teamColors[teamNumber],
        spawn: getTeamSpawn(teamNumber),
        jail: getTeamJail(teamNumber),
        playerCount: 0,
        botCount: 0,
    };
    
    config.maps.smear.teams.push(team);
    
    const teamJailCell = {
        id: 'teamJailCell' + team.i,
        barrier: true,
        r: JAIL_CELL_RADIUS,
        x: team.jail.x,
        y: team.jail.y,
        width: WALL_WIDTH,
        spawnTeam: undefined,
        hill: false,
    };
    config.maps.smear.walls.push(teamJailCell);
    
    const teamBarrier = {
        id: 'teamBarrier' + team.i,
        barrier: false,
        r: TEAM_BARRIER_RADIUS,
        x: team.spawn.x,
        y: team.spawn.y,
        width: WALL_BARRIER_WIDTH,
        spawnTeam: team.i,
        hill: false,
    }
    
    config.maps.smear.walls.push(teamBarrier);
    
    const teamF = getFlagPostForTeam(team.i);
    
    const teamFlagPost = {
        r: FLAG_POST_RADIUS, x: teamF.x, y: teamF.y, team: team.i
    };
    
    config.maps.smear.flagPosts.push(teamFlagPost);
}

// Setup smear
for(let i = 0; i < config.maps.smear.numberOfTeams; i++){
    addTeam(i);
}

export default config;