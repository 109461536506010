import 'phaser';
import LoaderScene from './scenes/LoaderScene';
import GameScene from './scenes/GameScene';
import * as Sentry from '@sentry/browser';

Sentry.onLoad(function() {
  Sentry.init({
    dsn: "https://dfe40413ed58b72b069f6f20537044b9@o4505977095192576.ingest.sentry.io/4505977097617408",
    enableTracing: false,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  // parent: "game",
  // fullscreenTarget: "game",
  fps: {
    target: 60,
    forceSetTimeOut: true,
    smoothStep: false,
  },
  width: window.innerWidth,
  height: window.innerHeight,
  zoom: 1,
  input: {
    keyboard: true,
    gamepad: true,
    touch: true,
  },
  render: {
    pixelArt: true,
    antialias: false,
    antialiasGL: false,
  },
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
    width: '100%',
    height: '100%',
    expandParent: true,
    parent: "game",
  },
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
      // gravity: {
      //   y: 500,
      // },
    },
  },
  scene: [LoaderScene, GameScene],
};

window.addEventListener('load', () => new Phaser.Game(config));
