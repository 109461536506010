// https://sfxr.me/
export default {
    fire: {
        laser:
        {
            "oldParams": true,
            "wave_type": 1,
            "p_env_attack": 0,
            "p_env_sustain": 1,
            "p_env_punch": 0,
            "p_env_decay": 0.959,
            "p_base_freq": 0.377,
            "p_freq_limit": 0.271,
            "p_freq_ramp": -0.233,
            "p_freq_dramp": -0.028,
            "p_vib_strength": 0,
            "p_vib_speed": 0,
            "p_arp_mod": 0.012,
            "p_arp_speed": 0,
            "p_duty": 0.8624372878898132,
            "p_duty_ramp": -0.42017869055126256,
            "p_repeat_speed": 0,
            "p_pha_offset": 0,
            "p_pha_ramp": 0,
            "p_lpf_freq": 1,
            "p_lpf_ramp": 0.001,
            "p_lpf_resonance": 0,
            "p_hpf_freq": 0,
            "p_hpf_ramp": 0,
            "sound_vol": 0.25,
            "sample_rate": 22050,
            "sample_size": 8
        },
        // {
        //     "oldParams": true,
        //     "wave_type": 1,
        //     "p_env_attack": 0,
        //     "p_env_sustain": 0.11664432175629386,
        //     "p_env_punch": 0.004506474165270324,
        //     "p_env_decay": 0.2846422610692642,
        //     "p_base_freq": 0.8177166611882842,
        //     "p_freq_limit": 0.039197689445550514,
        //     "p_freq_ramp": -0.5179480865566285,
        //     "p_freq_dramp": 0,
        //     "p_vib_strength": 0,
        //     "p_vib_speed": 0,
        //     "p_arp_mod": 0,
        //     "p_arp_speed": 0,
        //     "p_duty": 0.05639524313807576,
        //     "p_duty_ramp": 0.09811207932955918,
        //     "p_repeat_speed": 0,
        //     "p_pha_offset": 0,
        //     "p_pha_ramp": 0,
        //     "p_lpf_freq": 1,
        //     "p_lpf_ramp": 0,
        //     "p_lpf_resonance": 0,
        //     "p_hpf_freq": 0.11925129207074052,
        //     "p_hpf_ramp": 0,
        //     "sound_vol": 0.5,
        //     "sample_rate": 22050,
        //     "sample_size": 8
        //   }
        // {
        //     "oldParams": true,
        //     "wave_type": 1,
        //     "p_env_attack": 0,
        //     "p_env_sustain": 1,
        //     "p_env_punch": 0,
        //     "p_env_decay": 0.959,
        //     "p_base_freq": 0.351,
        //     "p_freq_limit": 0.216,
        //     "p_freq_ramp": -0.398,
        //     "p_freq_dramp": -0.131,
        //     "p_vib_strength": 0,
        //     "p_vib_speed": 0,
        //     "p_arp_mod": 0.012,
        //     "p_arp_speed": 0,
        //     "p_duty": 0.8624372878898132,
        //     "p_duty_ramp": -0.42017869055126256,
        //     "p_repeat_speed": 0,
        //     "p_pha_offset": 0,
        //     "p_pha_ramp": 0,
        //     "p_lpf_freq": 1,
        //     "p_lpf_ramp": 0.001,
        //     "p_lpf_resonance": 0,
        //     "p_hpf_freq": 0,
        //     "p_hpf_ramp": 0,
        //     "sound_vol": 1, // 0.238,
        //     "sample_rate": 22050,
        //     "sample_size": 8
        // }
        // {
        //     "oldParams": true,
        //     "wave_type": 1,
        //     "p_env_attack": 0,
        //     "p_env_sustain": 1,
        //     "p_env_punch": 0,
        //     "p_env_decay": 0.959,
        //     "p_base_freq": 1,
        //     "p_freq_limit": 0.193,
        //     "p_freq_ramp": -0.398,
        //     "p_freq_dramp": -0.131,
        //     "p_vib_strength": 0,
        //     "p_vib_speed": 0,
        //     "p_arp_mod": 0.012,
        //     "p_arp_speed": 0,
        //     "p_duty": 0.8624372878898132,
        //     "p_duty_ramp": -0.42017869055126256,
        //     "p_repeat_speed": 0,
        //     "p_pha_offset": 0,
        //     "p_pha_ramp": 0,
        //     "p_lpf_freq": 1,
        //     "p_lpf_ramp": 0.001,
        //     "p_lpf_resonance": 0,
        //     "p_hpf_freq": 0,
        //     "p_hpf_ramp": 0,
        //     "sound_vol": 0.25,
        //     "sample_rate": 44100,
        //     "sample_size": 8
        // }
        bouncy:
            {
                "oldParams": true,
                "wave_type": 1,
                "p_env_attack": 0,
                "p_env_sustain": 0.28764650225324173,
                "p_env_punch": 0.25096643862522444,
                "p_env_decay": 0.12798134438461783,
                "p_base_freq": 0.8655772353193805,
                "p_freq_limit": 0.5314041354051083,
                "p_freq_ramp": -0.29613285596684413,
                "p_freq_dramp": 0,
                "p_vib_strength": 0,
                "p_vib_speed": 0,
                "p_arp_mod": 0,
                "p_arp_speed": 0,
                "p_duty": 0.3688248272756264,
                "p_duty_ramp": 0.18989843158314865,
                "p_repeat_speed": 0,
                "p_pha_offset": 0,
                "p_pha_ramp": 0,
                "p_lpf_freq": 1,
                "p_lpf_ramp": 0,
                "p_lpf_resonance": 0,
                "p_hpf_freq": 0.0006343387289146074,
                "p_hpf_ramp": 0,
                "sound_vol": 0.25,
                "sample_rate": 22050,
                "sample_size": 8
        },
        missile: {
            "oldParams": true,
            "wave_type": 3,
            "p_env_attack": 0,
            "p_env_sustain": 0.3830162495028754,
            "p_env_punch": 0.612838056462595,
            "p_env_decay": 0.4378745409790328,
            "p_base_freq": 0.20794150818818427,
            "p_freq_limit": 0,
            "p_freq_ramp": 0.01865908881874931,
            "p_freq_dramp": 0,
            "p_vib_strength": 0.15957324951428534,
            "p_vib_speed": 0.16346962232873857,
            "p_arp_mod": 0,
            "p_arp_speed": 0,
            "p_duty": 0,
            "p_duty_ramp": 0,
            "p_repeat_speed": 0,
            "p_pha_offset": 0.4538636789401039,
            "p_pha_ramp": -0.0673815288081262,
            "p_lpf_freq": 1,
            "p_lpf_ramp": 0,
            "p_lpf_resonance": 0,
            "p_hpf_freq": 0,
            "p_hpf_ramp": 0,
            "sound_vol": 0.25,
            "sample_rate": 22050,
            "sample_size": 8
        },
        bomb: {
                "oldParams": true,
                "wave_type": 3,
                "p_env_attack": 0,
                "p_env_sustain": 0.2568448482414455,
                "p_env_punch": 0.49169793629459835,
                "p_env_decay": 0.32559909801247144,
                "p_base_freq": 0.14357791221272143,
                "p_freq_limit": 0,
                "p_freq_ramp": -0.2656501172555461,
                "p_freq_dramp": 0,
                "p_vib_strength": 0.26128200808776275,
                "p_vib_speed": 0.5502748841400691,
                "p_arp_mod": 0,
                "p_arp_speed": 0,
                "p_duty": 0,
                "p_duty_ramp": 0,
                "p_repeat_speed": 0.4385640454448157,
                "p_pha_offset": 0.0257770543559675,
                "p_pha_ramp": -0.29023634501311024,
                "p_lpf_freq": 1,
                "p_lpf_ramp": 0,
                "p_lpf_resonance": 0,
                "p_hpf_freq": 0,
                "p_hpf_ramp": 0,
                "sound_vol": 0.25,
                "sample_rate": 22050,
                "sample_size": 8
        },
    },
    player: {
        spawn:
        {
            "oldParams": true,
            "wave_type": 3,
            "p_env_attack": 0.5305078499455996,
            "p_env_sustain": 0.12012915870482412,
            "p_env_punch": 0.2057902838036315,
            "p_env_decay": 0.7261177085348209,
            "p_base_freq": 0.6945151344554952,
            "p_freq_limit": 0,
            "p_freq_ramp": -0.0201950975591334,
            "p_freq_dramp": 0.15051828656507632,
            "p_vib_strength": -0.797217716126824,
            "p_vib_speed": -0.06703239344343537,
            "p_arp_mod": -0.6003494442487876,
            "p_arp_speed": -0.9291055134503083,
            "p_duty": 0.23594930720199114,
            "p_duty_ramp": 0.11167392451224874,
            "p_repeat_speed": 0.6759381646652556,
            "p_pha_offset": -0.2873394956063753,
            "p_pha_ramp": 0.33450232693814425,
            "p_lpf_freq": 0.8682543321939684,
            "p_lpf_ramp": -0.08872175202272556,
            "p_lpf_resonance": -0.954761676427144,
            "p_hpf_freq": 0.2229772140213744,
            "p_hpf_ramp": -0.0057283494725495765,
            "sound_vol": 0.25,
            "sample_rate": 22050,
            "sample_size": 8
        },
        // {
        //     "oldParams": true,
        //     "wave_type": 1,
        //     "p_env_attack": 0,
        //     "p_env_sustain": 0.045881277141289936,
        //     "p_env_punch": 0.5682432198868481,
        //     "p_env_decay": 0.3011841151123399,
        //     "p_base_freq": 0.5331526318001091,
        //     "p_freq_limit": 0,
        //     "p_freq_ramp": 0,
        //     "p_freq_dramp": 0,
        //     "p_vib_strength": 0,
        //     "p_vib_speed": 0,
        //     "p_arp_mod": 0.34779068136176133,
        //     "p_arp_speed": 0.6321846128034829,
        //     "p_duty": 0,
        //     "p_duty_ramp": 0,
        //     "p_repeat_speed": 0,
        //     "p_pha_offset": 0,
        //     "p_pha_ramp": 0,
        //     "p_lpf_freq": 1,
        //     "p_lpf_ramp": 0,
        //     "p_lpf_resonance": 0,
        //     "p_hpf_freq": 0,
        //     "p_hpf_ramp": 0,
        //     "sound_vol": 1,
        //     "sample_rate": 22050,
        //     "sample_size": 8
        // },
        explosion:
        {
            "oldParams": true,
            "wave_type": 3,
            "p_env_attack": 0,
            "p_env_sustain": 0.21607259807807117,
            "p_env_punch": 0.4730582477527951,
            "p_env_decay": 0.4830679559812612,
            "p_base_freq": 0.03755117268221857,
            "p_freq_limit": 0,
            "p_freq_ramp": -0.011972631072435916,
            "p_freq_dramp": 0,
            "p_vib_strength": 0.5924372099197194,
            "p_vib_speed": 0.22054597184564528,
            "p_arp_mod": 0,
            "p_arp_speed": 0,
            "p_duty": 0,
            "p_duty_ramp": 0,
            "p_repeat_speed": 0,
            "p_pha_offset": 0.05218674028069786,
            "p_pha_ramp": -0.29212072432334807,
            "p_lpf_freq": 1,
            "p_lpf_ramp": 0,
            "p_lpf_resonance": 0,
            "p_hpf_freq": 0,
            "p_hpf_ramp": 0,
            "sound_vol": 0.3,
            "sample_rate": 22050,
            "sample_size": 8
          }
        // {
        //     "oldParams": true,
        //     "wave_type": 3,
        //     "p_env_attack": 0,
        //     "p_env_sustain": 0.38951043909363814,
        //     "p_env_punch": 0.3791263349391021,
        //     "p_env_decay": 0.4275340015305581,
        //     "p_base_freq": 0.09966801074054063,
        //     "p_freq_limit": 0,
        //     "p_freq_ramp": 0,
        //     "p_freq_dramp": 0,
        //     "p_vib_strength": 0.6044349977169025,
        //     "p_vib_speed": 0.568230666849115,
        //     "p_arp_mod": 0,
        //     "p_arp_speed": 0,
        //     "p_duty": 0,
        //     "p_duty_ramp": 0,
        //     "p_repeat_speed": 0.5019730451348128,
        //     "p_pha_offset": 0,
        //     "p_pha_ramp": 0,
        //     "p_lpf_freq": 1,
        //     "p_lpf_ramp": 0,
        //     "p_lpf_resonance": 0,
        //     "p_hpf_freq": 0,
        //     "p_hpf_ramp": 0,
        //     "sound_vol": 0.25,
        //     "sample_rate": 22050,
        //     "sample_size": 8
        // }
    },
    win: {
        "oldParams": true,
        "wave_type": 1,
        "p_env_attack": 0.061,
        "p_env_sustain": 0.9703587333803395,
        "p_env_punch": 0.3909646001650133,
        "p_env_decay": 0.8643277531491171,
        "p_base_freq": 0.738,
        "p_freq_limit": 0,
        "p_freq_ramp": 0.000028190473857729506,
        "p_freq_dramp": -0.7180104778787135,
        "p_vib_strength": 0.2611635843561703,
        "p_vib_speed": 0,
        "p_arp_mod": -0.101,
        "p_arp_speed": -0.7780379551643195,
        "p_duty": 0.054,
        "p_duty_ramp": -0.41559575794630343,
        "p_repeat_speed": 0.244,
        "p_pha_offset": -0.032182974075027085,
        "p_pha_ramp": 0.1919429057981839,
        "p_lpf_freq": 0.5734976024558642,
        "p_lpf_ramp": -0.05819669936831186,
        "p_lpf_resonance": -0.03604141078939227,
        "p_hpf_freq": 0.5591952142326,
        "p_hpf_ramp": 0.9220055794835066,
        "sound_vol": 0.25,
        "sample_rate": 22050,
        "sample_size": 8
    }
}