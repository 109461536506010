import GameInputs from '../inputs/GameInputs';
import Player from '../gameObjects/Player';

import { Client, Room } from 'colyseus.js';

import config from '../../../../config';
import Wall from '../gameObjects/Wall';
import FlagPost from '../gameObjects/FlagPost';
import Bullet from '../gameObjects/Bullet';
import Flag from '../gameObjects/Flag';

import getTeamColor from '../../../../common/functions/getTeamColor';
import getTeamName from '../../../../common/functions/getTeamName';
import SplashScene from './SplashScene';
import CenterTextScene from './CenterTextScene';
import { sfxr } from '../sound/sfxr.js';
import sfLibrary from '../sound/fxLibrary';
import UserInterfaceScene from './UserInterface';

export default class GameScene extends Phaser.Scene {
  private _inputs: GameInputs;
  
  client = new Client(config.colysusUrl);
  room: Room;
  playerEntities: { [sessionId: string]: Player } = {};
  bulletEntities: { [id: string]: Bullet } = {};
  flagEntity: Flag;
  currentPlayer: Player;

  // local input cache
  inputPayload = {
    left: false,
    right: false,
    up: false,
    down: false,
    sprint: false,
    tick: undefined,
    fire: undefined,
  };

  fixedTimeStep = config.fixedTimeStep; // 1000 / 60;
  elapsedTime = 0;
  currentTick: number = 0;
  hill: Wall;
  inJail = false;
  splash: SplashScene;
  centerTextScene: CenterTextScene;
  isMuted: boolean;
  ui: UserInterfaceScene;

  constructor() {
    super({ key: 'game', active: false, visible: false });
  }

  public preload() {
    if ('ontouchstart' in window || (navigator.maxTouchPoints > 0)) {
      this.load.plugin('rexvirtualjoystickplugin', '/assets/plugins/rexvirtualjoystickplugin.min.js', true);
    }
  }

  public async create() {

    this.drawStars();

    this.centerTextScene = new CenterTextScene('centerText', this);
    this.scene.add('centerText', this.centerTextScene, true);

    this.splash = new SplashScene('splash', this);
    this.scene.add('splash', this.splash, true);

    this.ui = new UserInterfaceScene('ui', this);
    this.scene.add('ui', this.ui, true);

    const resize = () => {
      this.scale.setGameSize(window.innerWidth, window.innerHeight)
    }
    window.addEventListener("resize", resize, false);

    this._inputs = new GameInputs(this, this.input, this.cameras.main);
    this.input.keyboard.on('keydown-M', () => {
      this.toggleMute();
    });
    
    this.createWalls();
    this.createFlagPosts();
    
    await this.join();
  }

  async leave() {
    this.centerTextScene.show('You have quit the game.', 1000 * 60 * 60 * 24);
    setTimeout(() => {
      this.splash.show();
      const shouldRejoin = confirm('Rejoin the game?');
      if (shouldRejoin) {
        window.location.reload();
      } else {
        window.close();
      }
    }, 2500);
  }

  teamWins(team) {
    if (team < 0) return;
    this.centerTextScene.show(`${getTeamName(team)} team wins!`);
    this.hill.setHillColor(getTeamColor(team));

    if (!this.game.sound.mute) {
      const a = sfxr.toAudio(sfLibrary.win);
      a.play();
    }
  }
  
  async join() {
    try {
      // Connect to server
      console.log('Joining room...');
      this.room = await this.client.joinOrCreate('my_room');
      console.log('Joined successfully!');
      // console.log(this.room);

      this.room.onLeave((code) => {
        console.log(`client left the room ${code}`);
        // 1000 - Regular socket shutdown
        // Between 1001 and 1015 - Abnormal socket shutdown
        // Between 4000 and 4999 - Custom socket close code (See more details)

        this.leave();
      });

      this.room.onError((code, message) => {
        console.log(`Oops, error ocurred: ${message} (code: ${code})`);
      });

      this.room.state.listen("lastTeamToWin", (team, prevTeam) => {
        this.teamWins(team);
      });

      this.room.state.messages.onChange(() => {
        this.ui.updateMessages(this.room.state.messages);
      });

      // listen for players
      this.room.state.players.onAdd((player, sessionId) => {
        const entity = new Player(this, player.x, player.y, player.name, getTeamColor(player.team), player); //this.physics.add.image(player.x, player.y, 'ship_0001');
  
        if (this.room.sessionId === sessionId) {
          // This user
          this.cameras.main
            .setBounds(0, 0, config.maps.smear.width, config.maps.smear.height)
            .startFollow(entity, true);
  
          setTimeout(() => {
            this.centerTextScene.show('Capture the flag!');
          }, 1000);
        }
  
        // keep a reference of it on `playerEntities`
        this.playerEntities[sessionId] = entity;
  
        if (sessionId === this.room.sessionId) {
          // this is the current player!
          // (we are going to treat it differently during the update loop)
          this.currentPlayer = entity;
          this.currentPlayer.setCurrentPlayer(true);
  
          player.onChange(() => {
            // console.log('player me changed', player);
            this.currentPlayer.x = player.x;
            this.currentPlayer.y = player.y;
            this.currentPlayer.energy = player.energy;
            this.currentPlayer.setScore(player.score);
            this.currentPlayer.setHealth(player.health);
            this.currentPlayer.setJail(player.inJail);
            this.currentPlayer.setPlayerName(player.name);
  
            if (player.inJail !== this.inJail) {
              this.inJail = player.inJail;
              if (this.inJail) {
                // Show died msg
                if (player.health === 0) {
                  this.centerTextScene.show('You died!');
                }
                // Show Splash
                setTimeout(() => {
                  this.splash.show();
                }, 2500);
              } else {
                if (!this.game.sound.mute) {
                  const a = sfxr.toAudio(sfLibrary.player.spawn);
                  a.play();
                };
              }
            }
          });
        } else {
          // all remote players are here!
          // (same as before, we are going to interpolate remote players)
          player.onChange(() => {
            // console.log('player changed', player);
            entity.setData('serverX', player.x);
            entity.setData('serverY', player.y);
            entity.setScore(player.score);
            entity.setHealth(player.health);
            entity.setJail(player.inJail);
            entity.setPlayerName(player.name);
          });
        }
  
        // // listening for server updates
        // player.onChange(() => {
        //   console.log('player position has changed', player, sessionId);
        //   // // update local position immediately
        //   // entity.x = player.x;
        //   // entity.y = player.y;
  
        //   //
        //   // do not update local position immediately
        //   // we're going to LERP them during the render loop.
        //   //
        //   entity.setData('serverX', player.x);
        //   entity.setData('serverY', player.y);
        // });
  
        // Alternative, listening to individual properties:
        // player.listen("x", (newX, prevX) => console.log(newX, prevX));
        // player.listen("y", (newY, prevY) => console.log(newY, prevY));
      });
      this.room.state.players.onRemove((player, sessionId) => {
        const entity = this.playerEntities[sessionId];
        if (entity) {
          entity.destroy();
          delete this.playerEntities[sessionId];
        }
      });
  
      this.room.state.bullets.onAdd((bullet, i) => {
        const teamColor = getTeamColor(bullet.team);
        let bulletFromCurrentPlayer = false;
        if (bullet.sessionId === this.room.sessionId) {
          // Current player shot this bullet
          bulletFromCurrentPlayer = true;
        }
        const entity = new Bullet(this, bullet, teamColor, bulletFromCurrentPlayer);
        this.bulletEntities[bullet.id] = entity;
        bullet.onChange(() => {
          entity.x = bullet.x;
          entity.y = bullet.y;
          entity.setRotation(bullet.rotation);
  
          if (bullet.type === 'bomb') {
            entity.updateBombSize();
          }
          // entity.setData('serverX', player.x);
          // entity.setData('serverY', player.y);
        });
      });
      this.room.state.bullets.onRemove((bullet, i) => {
        const entity = this.bulletEntities[bullet.id];
        if (entity) {
          entity.destroy();
  
          delete this.bulletEntities[bullet.id];
        }
      });
      this.createFlag();
    } catch (e) {
      console.error(e);
    }
  }

  createFlag(): void {
    this.flagEntity = new Flag(this, 0, 0);
    this.room.state.flag.onChange(() => {
      this.flagEntity.x = this.room.state.flag.x;
      this.flagEntity.y = this.room.state.flag.y;
      this.flagEntity.setDepth(9999);
      this.flagEntity.setDropped(this.room.state.flag.dropped);
    });
  }

  createWalls(): void {
    config.maps.smear.walls.forEach((wall) => {
      let wallColor = wall.barrier ? 0xcccccc : 0x333333;
      let fillColor;
      let fillAlpha = 0.1;
      if (wall.spawnTeam !== undefined) {
        fillColor = getTeamColor(wall.spawnTeam);
        fillAlpha = 0.1;
      }
      const entity = new Wall(
        this,
        wall.x,
        wall.y,
        wall.r,
        wallColor,
        wall.width,
        fillColor,
        fillAlpha
      );

      if (wall.hill) {
        this.hill = entity;
      }
    });
  }

  createFlagPosts(): void {
    config.maps.smear.flagPosts.forEach((flagPost) => {
      const entity = new FlagPost(
        this,
        flagPost.x,
        flagPost.y,
        flagPost.r,
        flagPost.hasOwnProperty('team') ? getTeamColor(flagPost.team) : 0xffffff
      );
    });
  }

  update(time: number, delta: number): void {
    // // skip loop if not connected with room yet.
    // if (!this.room) { return; }
    // // skip loop if not connected yet.
    // if (!this.currentPlayer) { return; }

    // // send input to the server
    // // this.inputPayload.left = this.cursorKeys.left.isDown;
    // // this.inputPayload.right = this.cursorKeys.right.isDown;
    // // this.inputPayload.up = this.cursorKeys.up.isDown;
    // // this.inputPayload.down = this.cursorKeys.down.isDown;
    // this.inputPayload.left = this.inputs.left;
    // this.inputPayload.right = this.inputs.right;
    // this.inputPayload.up = this.inputs.jump;
    // this.inputPayload.down = this.inputs.down;

    // this.room.send(0, this.inputPayload);

    // for (let sessionId in this.playerEntities) {
    //   // do not interpolate the current player
    //   if (sessionId === this.room.sessionId) {
    //       continue;
    //   }

    //   // interpolate all other player entities
    //   const entity = this.playerEntities[sessionId];
    //   const { serverX, serverY } = entity.data.values;

    //   entity.x = Phaser.Math.Linear(entity.x, serverX, 0.2);
    //   entity.y = Phaser.Math.Linear(entity.y, serverY, 0.2);
    // }

    // skip loop if not connected yet.
    if (!this.currentPlayer) {
      return;
    }

    this.elapsedTime += delta;
    while (this.elapsedTime >= this.fixedTimeStep && this.room.connection.isOpen) {
      this.elapsedTime -= this.fixedTimeStep;
      this.fixedTick(time, this.fixedTimeStep);
    }
  }

  getOrdinance() {
    // var ordinance = {};
    // if(keys.shift.isDown || isSecondary) {
    //     ordinance[secondaryWeaponSelected] = {
    //         x: game.input.activePointer.worldX,
    //         y: game.input.activePointer.worldY
    //     }
    //     // console.log('FIRE '+secondaryWeaponSelected);
    // } else {
    
    if (this.inputs.firePrimary) {
      return {
        type: 'laser',
        position: this.inputs.pointerWorldPosition,
      };
    } else if (this.inputs.fireMissile) {
      return {
        type: 'missile',
        position: this.inputs.pointerWorldPosition,
      };
    } else if (this.inputs.fireBouncy) {
      return {
        type: 'bouncy',
        position: this.inputs.pointerWorldPosition,
      };
    } else if (this.inputs.fireBomb) {
      return {
        type: 'bomb',
        position: this.inputs.pointerWorldPosition,
      };
    }
    return undefined;
  }

  fixedTick(time, delta) {
    this.currentTick++;

    // const currentPlayerRemote = this.room.state.players.get(this.room.sessionId);
    // const ticksBehind = this.currentTick - currentPlayerRemote.tick;
    // console.log({ ticksBehind });

    this.inputPayload.left = this.inputs.left;
    this.inputPayload.right = this.inputs.right;
    this.inputPayload.up = this.inputs.up;
    this.inputPayload.down = this.inputs.down;
    this.inputPayload.tick = this.currentTick;
    this.inputPayload.fire = this.getOrdinance();
    this.inputPayload.sprint = this.inputs.sprint;

    this.room.send(0, this.inputPayload);

    // if (this.inputPayload.left) {
    //     this.currentPlayer.x -= velocity;

    // } else if (this.inputPayload.right) {
    //     this.currentPlayer.x += velocity;
    // }

    // if (this.inputPayload.up) {
    //     this.currentPlayer.y -= velocity;

    // } else if (this.inputPayload.down) {
    //     this.currentPlayer.y += velocity;
    // }

    // this.localRef.x = this.currentPlayer.x;
    // this.localRef.y = this.currentPlayer.y;

    for (let sessionId in this.playerEntities) {
      // interpolate all player entities
      // (except the current player)
      if (sessionId === this.room.sessionId) {
        continue;
      }

      const entity = this.playerEntities[sessionId];
      const { serverX, serverY } = entity.data.values;

      if (entity.inJail) {
        entity.x = serverX;
        entity.y = serverY;
      } else {
        entity.x = Phaser.Math.Linear(entity.x, serverX, 0.2);
        entity.y = Phaser.Math.Linear(entity.y, serverY, 0.2);
      }
    }
  }
  
  toggleMute() {
    this.isMuted = !this.isMuted;

    // Mute or unmute all sounds in your game
    if (this.isMuted) {
        this.sound.mute = true;
    } else {
        this.sound.mute = false;
    }
    this.ui.setMuted(this.isMuted);
  }

  public get inputs() {
    return this._inputs;
  }

  drawStars() {
    const numStars = 200; // Adjust the number of stars as desired
    const centerX = config.maps.smear.width / 2;
    const centerY = config.maps.smear.height / 2;
    const circleRadius = (config.maps.smear.width/2) - 200; // Adjust the radius of the circular area

    const graphics = this.add.graphics();

    for (let i = 0; i < numStars; i++) {
      let angle = Phaser.Math.RND.angle(); // Generate a random angle in radians
      let distance = Phaser.Math.RND.between(circleRadius, circleRadius * 1.5); // Adjust the distance range as needed
      const x = centerX + Math.cos(angle) * distance;
      const y = centerY + Math.sin(angle) * distance;
      const size = Phaser.Math.RND.between(1, 3); // Adjust star size as desired
      const brightness = Phaser.Math.RND.between(200, 255); // Adjust brightness

      graphics.fillStyle(brightness << 16 | brightness << 8 | brightness);
      graphics.fillCircle(x, y, size);
    }
  }
}
