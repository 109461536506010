export default function(teamNumber) {
    // console.log(users, playerId)
    // console.log(team);
    if(teamNumber === 0) {
        return 0x00ff00; // green
    } else if(teamNumber === 1) {
        return 0xff0000; // red
    } else if(teamNumber === 2) {
        return 0x0000ff; // blue
    } else if(teamNumber === 3) {
        return 0xffff00; // yellow
    } else if(teamNumber === 4) {
        return 0xff6600; // orange
    } else if(teamNumber === 5) {
        return 0xad00ff; // purple
    } else if(teamNumber === 6) {
        return 0x5c2500; // brown
    } else if(teamNumber === 7) {
        return 0xe80199; // pink
    } else if(teamNumber === 8) {
        return 0x00ffff; // cyan
    }
    // 'orange',
    // 'purple',
    // 'brown',
    // 'pink',
    // 'cyan',
    // 'magenta',
    // 'maroon',
    // 'grey'
}