import GameScene from '../scenes/GameScene';
import config from '../../../../config';

export default class FlagPost extends Phaser.GameObjects.Container {
  constructor(
    scene: GameScene,
    x: number,
    y: number,
    radius: number,
    color: number
  ) {
    super(scene, x, y);
    const width = config.maps.smear.flagPostRadius / 3;
    const circle = new Phaser.GameObjects.Arc(
      scene,
      0,
      0,
      radius,
      0,
      360,
      false,
      color,
      0.8
    );
    circle.setStrokeStyle(width, 0x222222);
    this.add(circle);
    this.scene.add.existing(this);
  }
}
