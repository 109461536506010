import config from '../../../../config';
import GameScene from '../scenes/GameScene';
import { sfxr } from '../sound/sfxr.js';
import sfLibrary from '../sound/fxLibrary';

export default class Bullet extends Phaser.GameObjects.Container {
    halfwayPoint: Phaser.Math.Vector2;
    distanceToTravel: number;
    gameScene: any;
    constructor(scene: GameScene, bullet: any, teamColor: number, bulletFromCurrentPlayer = false) {
        const x = bullet.x
        const y = bullet.y
        super(scene, x, y);
        this.gameScene = scene;
        const rotation = bullet.rotation;
        const type = bullet.type;

        const Color = Phaser.Display.Color.IntegerToColor(teamColor);
        let bulletColor;
        if (!bulletFromCurrentPlayer) {
            bulletColor = Color.darken(30).color;
        } else {
            bulletColor = Color.color;
        }

        let obj;
        if(type === 'laser' || type === 'bombSrapnel') {
            obj = new Phaser.GameObjects.Line(scene, 0, 0, 0, 0, 16, 0, bulletColor, 1);
        } else if(type === 'missile') {
            obj = new Phaser.GameObjects.Line(scene, 0, 0, 0, 0, 20, 0, bulletColor, 1);
            obj.setLineWidth(4);
        } else if(type === 'bouncy') {
            obj = new Phaser.GameObjects.Line(scene, 0, 0, 0, 0, 30, 0, bulletColor, 1);
        } else if(type === 'bomb') {
            obj = new Phaser.GameObjects.Arc(scene, 0, 0, config.bullets.bomb.radius, 0, 360, false, bulletColor, 1);
            // Define the two points
            const point1 = new Phaser.Math.Vector2(bullet.x, bullet.y); // Replace x1 and y1 with your first point's coordinates
            const point2 = new Phaser.Math.Vector2(bullet.toX, bullet.toY); // Replace x2 and y2 with your second point's coordinates

            // Calculate the halfway point
            this.halfwayPoint = new Phaser.Math.Vector2((point1.x + point2.x) / 2, (point1.y + point2.y) / 2);
            this.distanceToTravel = Phaser.Math.Distance.BetweenPoints(point1, point2);
        }

        this.setRotation(rotation);
        
        this.add(obj);

        this.scene.add.existing(this);

        this.playFireSound(type);
    }

    updateBombSize() {
        const distanceLeft = Phaser.Math.Distance.BetweenPoints(
            this.halfwayPoint, new Phaser.Math.Vector2(this.x, this.y)
        );
        this.scale = 1 - (distanceLeft / (this.distanceToTravel / 2));
    }

    playFireSound(type: string) {
        if (this.scene.game.sound.mute) return;
        if (sfLibrary.fire.hasOwnProperty(type)) {
            const distance = Phaser.Math.Distance.BetweenPointsSquared(
                new Phaser.Math.Vector2(this.gameScene.currentPlayer.x, this.gameScene.currentPlayer.y),
                new Phaser.Math.Vector2(this.x, this.y)
            );
            const maxDistanceCanHear = this.gameScene.game.canvas.height * 0.5;
            const rateFromPlayer = 1 - (distance / (maxDistanceCanHear * maxDistanceCanHear));
            const audioConfig = {
                ...sfLibrary.fire[type],
            }
            if (rateFromPlayer > 0) {
                audioConfig.sound_vol = Phaser.Math.Clamp(audioConfig.sound_vol * rateFromPlayer, 0, 1);
                const a = sfxr.toAudio(audioConfig);
                a.play();
            }
        }
    }
}