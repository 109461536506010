import GameScene from '../scenes/GameScene';
import config from '../../../../config';

export default class Flag extends Phaser.GameObjects.Container {
  dropped = false;
  dropThrobInterval: NodeJS.Timer;
  constructor(
    scene: GameScene,
    x: number,
    y: number,
  ) {
    const radius = config.maps.smear.flagRadius;

    const fillColor = 0xffffff;
    const circle = new Phaser.GameObjects.Arc(
      scene,
      0,
      0,
      radius,
      0,
      360,
      false,
      fillColor,
      1
    );
    circle.setStrokeStyle(2, 0xcccccc, 1);
    super(scene, x, y);
    this.add(circle);
    this.scene.add.existing(this);
  }

  setDropped(dropped: boolean) {
    if (this.dropped != dropped) {
      this.dropped = dropped;
      if (dropped) {
        this.dropThrobInterval = setInterval(() => {
          this.setVisible(!this.visible);
        }, 250);
        // this.setVisible(false);
      } else {
        this.setVisible(true);
        clearInterval(this.dropThrobInterval);
      }
    }
  }
}
