export default function(teamNumber) {
    // console.log(users, playerId)
    // console.log(team);
    if(teamNumber === 0) {
        return 'Green'; // green
    } else if(teamNumber === 1) {
        return 'Red'; // red
    } else if(teamNumber === 2) {
        return 'Blue'; // blue
    } else if(teamNumber === 3) {
        return 'Yellow'; // yellow
    } else if(teamNumber === 4) {
        return 'Orange'; // orange
    } else if(teamNumber === 5) {
        return 'Purple'; // purple
    } else if(teamNumber === 6) {
        return 'Brown'; // brown
    } else if(teamNumber === 7) {
        return 'Pink'; // pink
    } else if(teamNumber === 8) {
        return 'Cyan'; // cyan
    }
    // 'orange',
    // 'purple',
    // 'brown',
    // 'pink',
    // 'cyan',
    // 'magenta',
    // 'maroon',
    // 'grey'
}