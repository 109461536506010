import config from '../../../../config';
import formatLargeNumber from '../../../../common/functions/formatLargeNumber';

// Import Amplify and configure it
import { Amplify, Auth } from 'aws-amplify'
Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_4Bnbzlbps',
    userPoolWebClientId: '7548jpm36l7fdrr7r8omr87fgk',
  },
});

const leaderboardColumns = [
    'player',
    'score',
    'high score',
    'kills/death',
    'accuracy',
    'wins/loss',
];

export default class SplashScene extends Phaser.Scene {
    parent: any;
    background: Phaser.GameObjects.Rectangle;
    modal: Phaser.GameObjects.Rectangle;
    discordText: Phaser.GameObjects.Text;
    discordLogo: Phaser.GameObjects.Image;
    modalWidth: number;
    modalHeight: number;
    modalX: number;
    modalY: number;
    leaderboardModal: any;
    leaderboardText: any;
    leadersTexts: Phaser.GameObjects.Text[] = [];
    isVisible: boolean;
    isLoggedIn: boolean = false;
    usernameText: Phaser.GameObjects.Text;
    usernameButton: Phaser.GameObjects.Graphics;
    
    constructor (handle, parent)
    {
        super(handle);

        this.parent = parent;
    }

    preload () {
        this.load.image('discordLogo', '/assets/img/discord-logo.png');
    }

    create () {
        // Create a black background rectangle covering the whole game screen
        this.background = this.add.rectangle(0, 0, window.innerWidth, window.innerHeight, 0x000000, 0.4);
        this.background.setOrigin(0);

        // Create the modal window
        this.modalWidth = config.splash.width;
        this.modalHeight = config.splash.height;
        this.modalX = (window.innerWidth - this.modalWidth) / 2;
        this.modalY = (window.innerHeight - this.modalHeight) / 2;

        this.modal = this.add.rectangle(this.modalX, this.modalY, this.modalWidth, this.modalHeight, 0xffffff);
        this.modal.setAlpha(1);
        this.modal.setOrigin(0);
        this.modal.setStrokeStyle(5, 0x003300, 1);

        // Create text for "Welcome" at the top of the modal
        const welcomeText = this.add.text(this.modalX + this.modalWidth / 2, this.modalY + 50, 'FortWars', {
            fontSize: '36px',
            color: '#000000',
            align: 'center',
            fontStyle: 'bold',
        });
        welcomeText.setOrigin(0.5);

        // Create text for "Objective" in the middle of the modal
        // const objectiveText = this.add.text(this.modalX + this.modalWidth / 2, this.modalY + 100, 'Objective', {
        //     fontSize: '24px',
        //     color: '#000000',
        //     align: 'center',
        //     fontStyle: 'bold',
        // });
        // objectiveText.setOrigin(0.5);

        // Create text for "Objective" in the middle of the modal
        const objectiveText2 = this.add.text(this.modalX + this.modalWidth / 2, this.modalY + 100, 'Capture the flag', {
            fontSize: '18px',
            color: '#000000',
            align: 'center',
            fontStyle: 'bold',
        });
        objectiveText2.setOrigin(0.5);

        // Create a button for "PLAY"
        const width = 200; // Width of the rectangle
        const height = 50; // Height of the rectangle
        const x = this.modalX + this.modalWidth / 2 - (width / 2); // X-coordinate of the rectangle
        const y = this.modalY + this.modalHeight - 50 - (height / 2); // Y-coordinate of the rectangle
        const cornerRadius = 10; // Radius of the rounded corners
        const fillColor = 0x000000; // Fill color in hexadecimal format
        const lineColor = 0x00CC00; // Line color in hexadecimal format
        const lineWidth = 2; // Line width in pixels
        
        const playButton = this.add.graphics();
        playButton.fillStyle(fillColor);
        playButton.lineStyle(lineWidth, lineColor);
        playButton.fillRoundedRect(x, y, width, height, cornerRadius);
        playButton.strokeRoundedRect(x, y, width, height, cornerRadius);

        const playText = this.add.text(this.modalX + this.modalWidth / 2, this.modalY + this.modalHeight - 50, 'PLAY', {
            fontSize: '36px',
            color: '#00CC00',
            align: 'center',
            fontStyle: 'bold',
        });
        playText.setOrigin(0.5);

        this.usernameButton = this.add.graphics();
        this.usernameButton.fillStyle(0xeeeeee);
        this.usernameButton.lineStyle(1, 0x000000);
        this.usernameButton.fillRect(x, y - 50, width, 36);
        this.usernameButton.strokeRect(x, y - 50, width, 36);

        let username = config.anonymouseUsername;
        if (this.parent.currentPlayer?.name) {
            username = this.parent.currentPlayer.name;
        }

        this.usernameText = this.add.text(this.modalX + this.modalWidth / 2, this.modalY + this.modalHeight - 108, username, {
            fontSize: '18px',
            color: '#000000',
            align: 'center',
            fontStyle: 'bold'
        });
        this.usernameText.setOrigin(0.5);
        this.usernameText.on('pointerup', () => {
            this.pickUsername();
        });
        this.usernameButton.on('pointerup', () => {
            this.pickUsername();
        });

        // Load the Discord logo image
        this.discordLogo = this.add.image(this.modalX + this.modalWidth - 120, this.modalY + this.modalHeight - 80, 'discordLogo');
        this.discordLogo.setOrigin(0.5);
        this.discordLogo.setScale(0.5); // Adjust the scale as needed

        // Create a text object for "Join us on Discord" with a link
        this.discordText = this.add.text(this.modalX + this.modalWidth - 120, this.modalY + this.modalHeight - 32, 'Join us on Discord', {
            fontSize: '18px',
            color: '#000000',
            align: 'center',
            fontStyle: 'bold'
        });
        this.discordText.setOrigin(0.5);
        this.discordText.on('pointerup', () => {
            window.open(config.splash.discordUrl, '_blank');
        });
        this.discordLogo.on('pointerup', () => {
            window.open(config.splash.discordUrl, '_blank');
        });

        this.background.on('pointerup', () => {
            this.hide();
        });
        this.modal.on('pointerup', () => {
            this.hide();
        });
        this.loginUser();
        this.show();
    }

    async updateUsername (username: string, userSub: string) {
        // console.log('updating username', username, userSub);
        this.parent.currentPlayer.setPlayerName(username);
        this.usernameText.setText(this.parent.currentPlayer.name);
        this.isLoggedIn = true;
        this.parent.room.send(1, { name: username, id: userSub });
    }

    async loginUser() {
        if (this.isLoggedIn) {
            return;
        }
        // Get username and password from local storage
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');

        try {
            if (username) {
                const cognitoUser = await Auth.signIn(username, password);
                // alert(`You are known as ${username}!`);
                this.updateUsername(username, cognitoUser.attributes.sub);
            }
        } catch (error) {
            console.error('Sign-in error', error);
            alert(error);
        }
    }

    async pickUsername () {
        if (this.parent.currentPlayer.name !== config.anonymouseUsername) {
            return;
        }
        if (this.parent.currentPlayer.score < config.scoreToSetUsername) {
            alert(`Your score must be at least ${config.scoreToSetUsername} to pick a username.`)
            return;
        }
        // Set user name
        let username = prompt('Pick your username', '');

        if (username.length && username.length > 10) {
            alert('Your username must be 10 characters or less.');
            return;
        }

        // Generate a random password
        let password = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 10; i++) {
            password += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        // Save to local storage
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);

        try {
            const signUpResult = await Auth.signUp({
                username,
                password,
                attributes: {
                    email: `u+${username}@fortwars.io`
                }
            });
            this.updateUsername(username, signUpResult.userSub);
            alert(`You are now known as ${username}!`);
        } catch (error) {
            console.error('Sign-up error', error);
            alert(error);
        }
    }

    show () {
        // this.cameras.main.setPosition(this.parent.x, this.parent.y);
        this.isVisible = true;

        this.background.setInteractive();
        this.modal.setInteractive();
        this.usernameButton.setInteractive();
        this.usernameText.setInteractive();
        this.discordText.setInteractive();
        this.discordLogo.setInteractive();
        this.scene.setVisible(true);
        this.scene.bringToTop();

        this.renderLeaderboard();
    }

    hide () {
        this.isVisible = false;
        this.background.removeInteractive();
        this.modal.removeInteractive();
        this.usernameButton.removeInteractive();
        this.usernameText.removeInteractive();
        this.discordLogo.removeInteractive();
        this.discordText.removeInteractive();
        this.scene.setVisible(false);
    }

    renderLeaderboard() {
        const leaders = this.getLeaders();

        const yOffset = 165;
        const leaderboardWidth = 600;

        // Create the modal window
        if (!this.leaderboardModal) {
            this.leaderboardModal = this.add.rectangle(this.modalX + this.modalWidth / 2, this.modalY + yOffset + 75, leaderboardWidth, 200, 0xcccccc);
            this.leaderboardModal.setAlpha(1);
            this.leaderboardModal.setOrigin(0.5);
            this.leaderboardModal.setStrokeStyle(5, 0x003300, 1);
        }
        // Create text for "Leaderboard" in the middle of the modal
        if (!this.leaderboardText) {
            this.leaderboardText = this.add.text(
                this.modalX + ((this.modalWidth - leaderboardWidth) / 2) + 8,
                this.modalY + yOffset - 20,
            'Leaderboard', {
                fontSize: '24px',
                color: '#000000',
                align: 'center',
                fontStyle: 'bold',
            });
            this.leaderboardText.setOrigin(0, 0);

            for (let i = 0; i < leaderboardColumns.length; i++) {
                const statText = this.add.text(
                    this.modalX + ((this.modalWidth - leaderboardWidth) / 2) + (i * 100) + 10,
                    this.modalY + yOffset + 15,
                    leaderboardColumns[i],
                {
                    fontSize: '14px',
                    color: '#000000',
                    align: 'center',
                    fontStyle: 'bold',
                });
                statText.setOrigin(0, 0);
                // this.leadersTexts.push(statText);
            }
        }

        this.leadersTexts.forEach(text => {
            text.destroy();
        });

        const formatDecimal = (num: number, fractionDigits = 1) => {
            if (!num) {
                return 0;
            } else {
                return num.toFixed(fractionDigits);
            }
        }

        for (let i = 0; i < Math.min(5, leaders.length); i++) {
            const leader = leaders[i];
            
            const leaderText = this.add.text(
                this.modalX + ((this.modalWidth - leaderboardWidth) / 2) + 10,
                this.modalY + yOffset + 40 + (i * 25),
                leader[0],
            {
                fontSize: '16px',
                color: '#000000',
                align: 'center',
                // fontStyle: 'bold',
            });
            leaderText.setOrigin(0, 0);
            this.leadersTexts.push(leaderText);
            if (leader[2]) {
                for (let c = 0; c < leaderboardColumns.length; c++) {
                    let text = '';
                    switch (leaderboardColumns[c]) {
                        case 'score':
                            text = leader[1];
                            break;
                        case 'kills/death':
                            text = `${formatDecimal(leader[2].kills / leader[2].deaths)}`;
                            break;
                        case 'accuracy':
                            // text = `${formatLargeNumber(leader[2].hits)}/${formatLargeNumber(leader[2].shots)}`;
                            text = `${formatDecimal(leader[2].hits / leader[2].shots * 100, 0)}%`;
                            break;
                        case 'wins/loss':
                            text = `${leader[2].wins}/${leader[2].losses}`;
                            break;
                        case 'high score':
                            text = leader[2].highscore;
                            break;
                    }
                    const statText = this.add.text(
                        this.modalX + ((this.modalWidth - leaderboardWidth) / 2) + (c * 100) + 10,
                        this.modalY + yOffset + 40 + (i * 25),
                        text,
                    {
                        fontSize: '14px',
                        color: '#000000',
                        align: 'center',
                        fontStyle: 'bold',
                    });
                    statText.setOrigin(0, 0);
                    this.leadersTexts.push(statText);
                }
            }
        }

        setTimeout(() => {
            if (this.isVisible) {
                this.renderLeaderboard();
            }
        }, 1000);
    }

    getLeaders () {
        let leaders = [];
        // if (this.parent.currentPlayer) {
        //     const currentPlayerEntry = ['You', this.parent.currentPlayer.score, this.parent.currentPlayer.];
        //     leaders.push(currentPlayerEntry);
        // }
        if (this.parent.playerEntities as {[key: string]: {}}) {
            for (var key of Object.keys(this.parent.playerEntities)) {
                const player = this.parent.playerEntities[key];
                // if (!player.isCurrentPlayer) {
                    const entry = [player.name, player.score, player.playerState.stats];
                    leaders.push(entry);
                // }
            }
        }

        leaders.sort((a, b) => {
            return b[1] - a[1];
        });

        return leaders;
    }
}