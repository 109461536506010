import config from '../../../../config';

const marginFromCorner = 50;
const weaponIndicators = [
    {
        name: 'sprint',
        label: '⇧',
        energy: config.sprint.energy,
        level: 0,
    },
    {
        name: 'laser',
        label: '🖱️',
        energy: config.bullets.laser.energy,
        level: config.bullets.laser.level,
    },
    {
        name: 'bouncy',
        label: 'E',
        energy: config.bullets.bouncy.energy,
        level: config.bullets.bouncy.level,
    },
    {
        name: 'missile',
        label: 'Q',
        energy: config.bullets.missile.energy,
        level: config.bullets.missile.level,
    },
    {
        name: 'bomb',
        label: 'G',
        energy: config.bullets.bomb.energy,
        level: config.bullets.bomb.level,
    },
];
export default class UserInterfaceScene extends Phaser.Scene {
    parent: any;
    volumeIcon: any;
    weaponOverlays = [];
    weapons: {
        sprint: {
            label: Phaser.GameObjects.Text,
            overlay: Phaser.GameObjects.Rectangle,
            tooltip: Phaser.GameObjects.Text,
        },
        laser: {
            label: Phaser.GameObjects.Text,
            overlay: Phaser.GameObjects.Rectangle,
            tooltip: Phaser.GameObjects.Text,
        },
        bouncy: {
            label: Phaser.GameObjects.Text,
            overlay: Phaser.GameObjects.Rectangle,
            tooltip: Phaser.GameObjects.Text,
        },
        missile: {
            label: Phaser.GameObjects.Text,
            overlay: Phaser.GameObjects.Rectangle,
            tooltip: Phaser.GameObjects.Text,
        },
        bomb: {
            label: Phaser.GameObjects.Text,
            overlay: Phaser.GameObjects.Rectangle,
            tooltip: Phaser.GameObjects.Text,
        },
    } = {
        sprint: {
            label: null,
            overlay: null,
            tooltip: null,
        },
        laser: {
            label: null,
            overlay: null,
            tooltip: null,
        },
        bouncy: {
            label: null,
            overlay: null,
            tooltip: null,
        },
        missile: {
            label: null,
            overlay: null,
            tooltip: null,
        },
        bomb: {
            label: null,
            overlay: null,
            tooltip: null,
        },
    };
    messages: Phaser.GameObjects.Text;
    
    constructor (handle, parent) {
        super(handle);
        this.parent = parent;
    }

    preload () {
    }

    create () {
        // Create server message text at top left
        const textStyle = {
            font: '14px Arial',
            fill: '#ffffff',
            align: 'left',
        };
        this.messages = this.add.text(10, 10, 'FortWars', textStyle);
        this.messages.setShadow(0, 0, 'rgba(0,0,0,1)', 1);
        this.messages.setAlign('left');
        this.messages.setOrigin(0, 0);

        // Create buttons for weapon indicators
        const weaponIndicatorWidth = 50;
        const weaponIndicatorHeight = 50;
        const weaponIndicatorMargin = 10;
        const weaponIndicatorY = window.innerHeight - marginFromCorner - 100;
        const weaponIndicatorX = window.innerWidth - marginFromCorner;
        // const weaponIndicatorXOffset = weaponIndicatorWidth + weaponIndicatorMargin;
        const weaponIndicatorYOffset = weaponIndicatorHeight + weaponIndicatorMargin;
        
        for (let i = 0; i < weaponIndicators.length; i++) {
            const weaponIndicator = this.add.rectangle(
                weaponIndicatorX,
                weaponIndicatorY - (weaponIndicatorYOffset * i),
                weaponIndicatorWidth,
                weaponIndicatorHeight,
                0xffffff
            );
            weaponIndicator.setOrigin(0.5);
            weaponIndicator.setAlpha(0.85);
            weaponIndicator.setInteractive();

            // Add label indicator for weapon
            const weaponIndicatorLabel = this.add.text(
                weaponIndicatorX,
                weaponIndicatorY - (weaponIndicatorYOffset * i),
                weaponIndicators[i].label,
                {
                    fontSize: '24px',
                    color: '#000000',
                    align: 'center',
                    fontFamily: 'Arial'
                    // fontStyle: 'bold',
                }
            );
            weaponIndicatorLabel.setOrigin(0.5);
            this.weapons[weaponIndicators[i].name].label = weaponIndicatorLabel;

            // Weapon indicator tooltip
            const weaponIndicatorTooltip = this.add.text(
                weaponIndicatorX - (weaponIndicatorWidth / 2) - 10,
                weaponIndicatorY - (weaponIndicatorYOffset * i),
                weaponIndicators[i].name,
                {
                    fontSize: '24px',
                    color: '#ffffff',
                    align: 'center',
                    fontFamily: 'Arial'
                    // fontStyle: 'bold',
                }
            );
            weaponIndicatorTooltip.setOrigin(1, 0.5);
            weaponIndicatorTooltip.setVisible(false);
            this.weapons[weaponIndicators[i].name].tooltip = weaponIndicatorTooltip;
            
            // Add overlay to gray out the weapon indicator if it's not available
            const weaponIndicatorOverlay = this.add.rectangle(
                weaponIndicatorX,
                weaponIndicatorY - (weaponIndicatorYOffset * i),
                weaponIndicatorWidth,
                weaponIndicatorHeight,
                0x000000,
                0.5
            )
            weaponIndicatorOverlay.name = weaponIndicators[i].name;

            weaponIndicator.on('pointerover', (pointer, gameObject) => {
                weaponIndicatorTooltip.setVisible(true);
            });
        
            weaponIndicator.on('pointerout', (pointer, gameObject) => {
                weaponIndicatorTooltip.setVisible(false);
            });

            this.weaponOverlays.push(weaponIndicatorOverlay);
        }

        setInterval(() => {
            this.updateAvailableWeapons();
        }, 1000);

        this.volumeIcon = this.add.text(window.innerWidth - marginFromCorner, window.innerHeight - marginFromCorner, '🔊', {
            fontSize: '32px',
            color: '#ffffff',
            align: 'center',
            fontStyle: 'bold',
        });
        this.volumeIcon.setOrigin(0.5).setAlpha(1);
        this.volumeIcon.setInteractive();
        this.volumeIcon.on('pointerdown', () => {
            this.parent.toggleMute();
        });
    }

    updateAvailableWeapons () {
        const playerEnergy = this.parent.currentPlayer?.energy;
        const playerScore = this.parent.currentPlayer?.score;
        this.weaponOverlays.forEach((overlay) => {
            const weapon = weaponIndicators.find((weapon) => weapon.name === overlay.name);
            if (weapon.energy > playerEnergy || weapon.level > playerScore) {
                overlay.setAlpha(1);
            } else {
                overlay.setAlpha(0);
            }
            if (weapon.level > playerScore) {
                this.weapons[weapon.name].label.setText('🔒');
                this.weapons[weapon.name].tooltip.setText(`Locked until level ${weapon.level}`);
            } else {
                this.weapons[weapon.name].label.setText(weapon.label);
                this.weapons[weapon.name].tooltip.setText(weapon.name);
            }
        });
    }

    updateMessages(messages) {
        const messageText = messages.join('\n');
        this.messages.setText(messageText);
    }

    setMuted (isMuted: boolean) {
        if (isMuted) {
            this.volumeIcon.setText('🔈');
        } else {
            this.volumeIcon.setText('🔊');
        }
    }
}