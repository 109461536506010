import config from '../../../../config';

const TEXT_CAPTURE_THE_FLAG = 'Capture the Flag';
const TEXT_YOU_DIED = 'You died!';
const TEXT_YOU_WON = 'team wins!';

export default class CenterTextScene extends Phaser.Scene {
    parent: any;
    background: Phaser.GameObjects.Rectangle;
    text: Phaser.GameObjects.Text;
    
    constructor (handle, parent) {
        super(handle);
        this.parent = parent;
    }

    preload () {
    }

    create () {
        this.text = this.add.text(window.innerWidth / 2, window.innerHeight / 2, 'FortWars', {
            fontSize: '52px',
            color: '#ffffff',
            align: 'center',
            fontStyle: 'bold',
        });
        this.text.setOrigin(0.5).setAlpha(0);
    }

    show (txt: string, duration: number = 10000) {
        this.scene.setVisible(true);
        // this.scene.bringToTop();

        this.text.setText(txt);

        this.tweens.add({
            targets: this.text,
            alpha: 1,
            duration: 500,
            ease: 'Power2'
        });

        setTimeout(() => {
            this.tweens.add({
                targets: this.text,
                alpha: 0,
                duration: 1000,
                ease: 'Power2'
            });
        }, duration);
    }

    hide () {
        this.scene.setVisible(false);
    }
}